<div
  *ngIf="!useMarkerCluster"
  class="map"
  leaflet
  [leafletOptions]="options"
  [leafletBaseLayers]="baseLayers"
  [leafletLayers]="layers"
  (leafletMapReady)="onMapReady($event)"
></div>

<div
  *ngIf="useMarkerCluster"
  class="map"
  leaflet
  [leafletOptions]="options"
  [leafletBaseLayers]="baseLayers"
  [leafletMarkerCluster]="layers"
  [leafletMarkerClusterOptions]="markerClusterOptions"
  (leafletMapReady)="onMapReady($event)"
></div>
