import { Component, Output, Input, EventEmitter } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { temperatureColor } from '@nx-workspace/static-data/streetlight/map-assets';

@Component({
  selector: 'app-color-sketch',
  templateUrl: './color-sketch.component.html',
  styleUrls: ['./color-sketch.component.scss'],
})
export class ColorSketchComponent {
  @Input() color = '';
  @Input() isRgb = true;
  @Output() onUpdateColor: EventEmitter<string> = new EventEmitter<string>();

  colors: string[] = [
    '#fe0000',
    '#00fe00',
    '#0000fe',
    '#fe00fe',
    '#00fefe',
    '#fefe00',
    '#000',
  ];
  temperatureColors: string[] = Object.values(temperatureColor);
  circleSize = 24;
  circleSpacing = 12;

  constructor() {}

  handleChange($event: ColorEvent) {
    const color = $event.color.hex;
    this.onUpdateColor.emit(color);
  }
}
