<div
  class="welcome-page"
  style="background-image: url('../../../assets/images/welcome-page-bg.jpg')"
>
  <div class="welcome-page-box">
    <div class="welcome-page-inner" *ngIf="deviceDetectorService.isDesktop()">
      <p class="welcome-page-txt">
        {{ 'WELCOME_PAGE.MOBILE_ONLY' | translate }}
      </p>
      <br />
      <p>{{ 'WELCOME_PAGE.MOBILE_ONLY_DESCRIPTION' | translate }}</p>
    </div>
    <div class="welcome-page-inner" *ngIf="!deviceDetectorService.isDesktop()">
      <p class="welcome-page-txt">
        {{ 'WELCOME_PAGE.DESCRIPTION' | translate }}
      </p>
      <mat-list role="list">
        <mat-list-item role="listitem">{{
          'WELCOME_PAGE.POINT_ONE' | translate
        }}</mat-list-item>
        <mat-list-item role="listitem">{{
          'WELCOME_PAGE.POINT_TWO' | translate
        }}</mat-list-item>
        <mat-list-item role="listitem">{{
          'WELCOME_PAGE.POINT_THREE' | translate
        }}</mat-list-item>
        <mat-list-item role="listitem">{{
          'WELCOME_PAGE.POINT_FOUR' | translate
        }}</mat-list-item>
      </mat-list>
      <button mat-flat-button class="btn btn-large btn-dark" routerLink="/map">
        {{ 'WELCOME_PAGE.BUTTON' | translate }}
      </button>
    </div>
  </div>
</div>
