import { Component, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-re-captcha-v2',
  templateUrl: './re-captcha-v2.component.html',
  styleUrls: ['./re-captcha-v2.component.scss'],
})
export class ReCaptchaV2Component {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onUpdateCaptchaToken: EventEmitter<string> =
    new EventEmitter<string>();

  siteKeyCaptchaV2: string = environment.recaptcha.siteKeyV2;

  resolved(captchaResponse: string) {
    this.onUpdateCaptchaToken.emit(captchaResponse);
  }
}
