<div class="captcha-box">
  <h1>Google reCaptcha v3</h1>
  <div class="captcha-inner">
    <div class="g-recaptcha"></div>
    <button
      mat-flat-button
      class="btn btn-large btn-dark"
      (click)="executeImportantAction()"
      data-badge="inline"
    >
      {{ 'CAPTCHA.BUTTON' | translate }}
    </button>
    <ng-template [ngIf]="isSuccess">
      <div class="form-subinfo">
        <span>{{ 'CAPTCHA.SUCCESS' | translate }}</span>
      </div>
    </ng-template>
    <ng-template [ngIf]="isWrong">
      <div class="form-subinfo">
        <span class="lnk" (click)="reload()">{{
          'CAPTCHA.WRONG' | translate
        }}</span>
      </div>
    </ng-template>
  </div>
</div>
