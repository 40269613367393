<button
  mat-icon-button
  *ngFor="let ratingId of ratingArr; index as i"
  [id]="'star_' + i"
  (click)="onClick(i + 1)"
>
  <mat-icon>
    {{ showIcon(i) }}
  </mat-icon>
</button>
