import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DialogConfirmComponent } from './dialog-confirm.component';
import { MaterialModule } from '@nx-workspace/material/material-module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DialogConfirmComponent],
  exports: [DialogConfirmComponent],
  imports: [CommonModule, MaterialModule, TranslateModule, FormsModule],
})
export class DialogConfirmModule {}
