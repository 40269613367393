import * as L from 'leaflet';
import 'leaflet-lasso';
import './custom-controls';

export const fullscreenControl = L.control.fullscreen({
  position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, defaut topleft
  title: 'Full Screen', // change the title of the button, default Full Screen
  titleCancel: 'Exit Full Screen', // change the title of the button when fullscreen is on, default Exit Full Screen
  content: '<i id="fullscreen-btn" class="material-icons">fullscreen</i>', // change the content of the button, can be HTML, default null
  forceSeparateButton: false, // force seperate button to detach from zoom buttons, default false
  forcePseudoFullscreen: false, // force use of pseudo full screen even if full screen API is available, default false
  fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to map._container
});

export const refreshControl = L.control.refresh({
  position: 'topleft',
  title: 'Refresh',
  content: '<i id="refresh-btn" class="material-icons">refresh</i>',
  forceSeparateButton: false,
});

export const channelsControl = L.control.channels({
  position: 'topright',
  title: 'View Channels',
  titleCancel: 'Close Channels',
  content: '<i id="channels-btn" class="material-icons"></i>',
  forceSeparateButton: true,
});

export const locationControl = L.control.location({
  position: 'topleft',
  title: 'Location',
  content: '<i id="location-btn" class="material-icons">location_searching</i>',
  forceSeparateButton: false,
});

export const leafletLassoControl = L.control.leafletLasso({
  position: 'topleft',
  title: 'Add to group',
  content: '<i id="lasso-btn"></i>',
  forceSeparateButton: true,
});
