import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingWrapperComponent } from './loading-wrapper.component';
import { SpinnerButtonDirective } from './spinner-button.directive';
import { MaterialModule } from '@nx-workspace/material/material-module';

export { LoadingWrapperComponent };

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [LoadingWrapperComponent, SpinnerButtonDirective],
  exports: [LoadingWrapperComponent, SpinnerButtonDirective],
})
export class LoadingModule {}
