<header class="header mat-elevation-z3">
  <div class="header-logo">
    <img src="../../../assets/images/first-header-logo.png" alt="" />
    <img src="../../../assets/images/second-header-logo.png" alt="" />
  </div>
  <div class="select-container">
    <mat-select
      [value]="translate.currentLang"
      class="lang"
      #langSelect
      panelClass="langSelect"
      (selectionChange)="changeTranslation(langSelect.value)"
    >
      <mat-option *ngFor="let lang of translate.getLangs()" [value]="lang">
        {{ lang }}
      </mat-option>
    </mat-select>
  </div>
</header>
