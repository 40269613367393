<div #color>
  <color-circle
    tabindex="0"
    [color]="color"
    [colors]="isRgb ? colors : temperatureColors"
    [class.brightness]="isRgb"
    [circleSize]="circleSize"
    [circleSpacing]="circleSpacing"
    [width]="color.offsetWidth"
    (onChange)="handleChange($event)"
  >
  </color-circle>
</div>
