<div
  class="loading-wrapper"
  [class.loading-wrapper--visible]="isLoading"
  [class.loading-wrapper--fade-in]="isLoading"
>
  <div *ngIf="isLoading" class="loading-wrapper__container">
    <mat-spinner></mat-spinner>
    <span *ngIf="message" class="loading-wrapper__message">
      {{ message }}
    </span>
  </div>
</div>
<ng-content></ng-content>
